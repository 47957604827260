module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TCX2SD8P","includeInDevelopment":false,"routeChangeEventName":"ignore-me","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sico","short_name":"Sico","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/husky-site/node_modules/@husky-x/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0c74dc44cb807c71470bd541b3834402"},
    },{
      plugin: require('../node_modules/@phx-husky/gatsby-cookie-consent-husky2/gatsby-browser.js'),
      options: {"plugins":[],"clientID":"208cd055c45a49c9a1b874fe394a829e","clientSecret":"virD3px42LhsIUdXXeXRJKnM7aELP4r0","websiteUrl":"siconfio.com.mx","activeEnv":"production"},
    },{
      plugin: require('../node_modules/@husky-x/gatsby-theme-husky/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sico","short_name":"Sico","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/@husky-x/gatsby-theme-husky/static/images/sico-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ca59c75a63c1d011c438773951d4849a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["X-XSS-Protection: 1; mode=block","X-Content-Type-Options: nosniff","Referrer-Policy: same-origin","X-Frame-Options: SAMEORIGIN"],"mergeSecurityHeaders":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
